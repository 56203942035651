.card-container {
	@apply flex flex-col items-center p-4 bg-white shadow-lg rounded-lg;
}

.card-photo {
	@apply w-16 h-16 rounded-full border-2 border-gray-300 mb-4;
}

.card-caricature {
	@apply w-32 h-32 object-cover mb-4;
}

.card-name {
	@apply text-lg font-semibold mb-2;
}

.card-testimonial {
	@apply text-sm text-gray-600 mb-4 text-center;
}

.card-button {
	@apply px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition;
}

.scrollbar-thin::-webkit-scrollbar {
	@apply h-1.5;
}
.scrollbar-thin::-webkit-scrollbar-track {
	@apply bg-transparent rounded-full;
}
.scrollbar-thin::-webkit-scrollbar-thumb {
	@apply bg-indigo-200 rounded-full hover:bg-indigo-300 transition-colors;
}
.testimony-card-enter {
	@apply opacity-0 translate-y-4;
}

.testimony-card-enter-active {
	@apply opacity-100 translate-y-0 transition-all duration-300;
}

.testimony-card-exit {
	@apply opacity-100 translate-y-0;
}

.testimony-card-exit-active {
	@apply opacity-0 translate-y-4 transition-all duration-300;
}
